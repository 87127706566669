
































































	import {Stored} from 'vue-stored-prop-decorator';
	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	import {Facture} from '@/models';
	import storeCubageLivraison from '@/stores/modules/cubageLivraison';
	import axios from "axios";
	import CrudCubageLivraisonListForSelect from '@/components/Crud/CubageLivraison/ListForSelect.vue';
	import {Token} from "@/models";
	import storeToken from "@/stores/modules/token";
	import CrudCubageLivraisonFilterForSelect from '@/components/Crud/CubageLivraison/FilterForSelect.vue';

	@Component({
		components: {
			CrudCubageLivraisonListForSelect,
			CrudCubageLivraisonFilterForSelect,
		}
	})
	export default class CrudLivraisonSelectDialog extends Vue {
		@InOut({ type: Facture  , isVModel: true }) private value!: Facture;
		private storeCubageLivraison = storeCubageLivraison;
		@Stored(() => storeToken) private token: Token;

		@Emit()
		private async onSubmit(facture: Facture): Promise<void> {
			// On affecte les factures
			for (const element of this.storeCubageLivraison.state.cubageLivraisons.data)
			{
				if (element.selected == true)
				{
					// On affecte la nouvelle facture
					await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/livraisons/" + element.livraison.id + "/putFacture/" + this.value.id + "?token=" + this.token.id, {});
				}
			}

			this.onClose();
		}

		private EffacerFiltres()
		{
			storeCubageLivraison.state.filters.clear();
		}

		private SelectAll()
		{
			
			this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
				element.selected = true;
			});
		}

		private SelectNone()
		{
			this.storeCubageLivraison.state.cubageLivraisons.data.forEach(element => {
				element.selected = false;
			});
		}

		@Emit()
		private onClose() {
			this.value = null;
		}

}

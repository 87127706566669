






























import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {Facture} from '@/models';
import CrudFactureRetardList from '@/components/Crud/Facture/ListRetard.vue';
import axios from "axios";
import storeToken from "@/stores/modules/token";
import {Token, User} from "@/models";

@Component({
	components: {
		CrudFactureRetardList,
	}
})
export default class FacturesView extends Vue {
	
	@Stored(() => storeToken) private token!: Token;

	private data = null;

	public async mounted(): Promise<void> {
		await this.LoadEncours();
	}

	private async LoadEncours(): Promise<void> 
	{
		await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/factures/fetch_encours?token=" + this.token.id, {}).then(async response => {
			this.data = await response.data;
		});

	}
}
















































































































































































































































































































































	import {Component} from '@/shared/component';
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
	import Checked from '@/components/Checked.vue';
	import {Facture, CubageFacture} from '@/models';
	import storeFacture from '@/stores/modules/facture';
	import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
	import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';
	import DialogConfirm from '@/components/Dialog/Confirm.vue';
	import CrudCubageFactureEditDialog from '@/components/Crud/CubageFacture/EditDialog.vue';
	import CrudFactureEditDialog from '@/components/Crud/Facture/EditDialog.vue';
	import CrudFactureEditDialogReglements from '@/components/Crud/Facture/EditDialogReglements.vue';
	import CrudLivraisonForFactureList from '@/components/Crud/Livraison/ListForFacture.vue';
	import CrudDocumentFactureShow from '@/components/Crud/DocumentFacture/Show.vue';
	import CrudDocumentFactureUploader from '@/components/Crud/DocumentFacture/Uploader.vue';
	import axios from "axios";
	import storeToken from "@/stores/modules/token";
	import {Stored} from 'vue-stored-prop-decorator';
	import {Token, User} from "@/models";
	import storeCubageFacture from '@/stores/modules/cubageFacture';

	@Component({
		components: {
			CrudTiersShow,
			CrudTiersEdit,
			Checked,
			DialogConfirm,
			CrudFactureEditDialog,
			CrudFactureEditDialogReglements,
			CrudLivraisonForFactureList,
			CrudDocumentFactureShow,
			CrudDocumentFactureUploader,
			CrudCubageFactureEditDialog,
		}
	})
	export default class CrudFactureShow extends AbstractShowVue<Facture> {

		@Stored(() => storeToken) private token: Token;
		private markPayerConfirm: boolean = false;
		private editFacture: Facture = null;
		private editSuiviReglements: Facture = null;
		private editCubageFacture: CubageFacture = null;
		private cubageFactures = null;
		private cubageTotaux = null;
		private idCubageToDelete: number = 0;
		private okCubageToDelete: boolean = false;

		public constructor() {
			super(storeFacture);
		}

		public async mounted(): Promise<void> {
      		await super.mounted();

			await this.LoadCubagesFacture();
		}

		private async LoadCubagesFacture(): Promise<void> 
		{
			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-factures/get-for-facture/" + this.item.id + "?token=" + this.token.id, {}).then(async response => {
				this.cubageFactures = await response.data;
			});

			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/factures/fetch_totaux/" + this.item.id + "?token=" + this.token.id, {}).then(async response => {
				this.cubageTotaux = await response.data;
			});
		}

		private async factureMarkPayer(): Promise<void> {
			await storeFacture.dispatch('putMarkPaid', this.item);
			await Promise.all([
				storeFacture.dispatch('get', this.item.id),
			]);
		}

		private createCubageFacture()
		{
			var newcubagefacture = new CubageFacture();
			newcubagefacture.facture = this.item;

			this.editCubageFacture = newcubagefacture;
		}

		private async editCubage(ID : number): Promise<void> 
		{
			this.editCubageFacture = await storeCubageFacture.dispatch('get', ID);
		}		

		private preparDelete(id: number)
		{
			this.idCubageToDelete = id;
			this.okCubageToDelete = true;
		}

		private async deleteFromFacture(): Promise<void> {
			console.log(this.idCubageToDelete);
			await axios.delete(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-factures/" + this.idCubageToDelete + "?token=" + this.token.id, {});
			this.idCubageToDelete = 0;
			await this.LoadCubagesFacture();
		}


	}



































































































	import {Prop} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import storeFacture from '@/stores/modules/facture';
  	import Checked from '@/components/Checked.vue';

	import {
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxFilterRow,
		DxHeaderFilter,
		DxGroupPanel,
		DxGrouping,
		DxScrolling,
		DxSummary,
		DxLookup,
		DxTotalItem,
		DxGroupItem,
		DxMasterDetail,
		DxStringLengthRule,
		DxRequiredRule,
		DxRangeRule,
		DxValueFormat,
		DxPaging,
		DxStateStoring,
		DxPager,
	} from 'devextreme-vue/data-grid';
	import DxButton from 'devextreme-vue/button';
	import storeToken from "@/stores/modules/token";
	import {Facture, User, Token} from "@/models";
	import {Stored} from "vue-stored-prop-decorator";
	import storeUser from '@/stores/modules/user';
	import {Emit} from 'vue-property-decorator';
	import axios from "axios";
import dxDataGrid from "devextreme/ui/data_grid";

	@Component({
		components: {
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxFilterRow,
			DxHeaderFilter,
			DxGroupPanel,
			DxGrouping,
			DxScrolling,
			DxSummary,
			DxLookup,
			DxTotalItem,
			DxGroupItem,
			DxMasterDetail,
			DxStringLengthRule,
			DxRangeRule,
			DxRequiredRule,
			DxValueFormat,
			DxPaging,
			DxPager,
			DxStateStoring,
			DxButton,
		}
	})
	export default class CrudFactureRetardList extends Vue {

		@Stored(() => storeToken) token!: Token;

		private dataGrid;
		private dataSource = null;

		public async mounted(): Promise<void> {
			await this.LoadEncours();
		}

		private async LoadEncours(): Promise<void> 
		{
			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/factures/fetch_retard?token=" + this.token.id, {}).then(async response => {
				this.dataSource = await response.data.data;
			});

			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
			this.dataGrid.instance.option("DataSourec", this.dataSource)
		}

		public refresh() {
			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
			this.dataGrid.instance.refresh()
		}

		public clearFilters() {
			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
			this.dataGrid.instance.clearFilter()
		}

		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/suivireglements?token=${this.token.id}`;
		}

		private hrefScieriePdf(id) {
			window.open(`${process.env.VUE_APP_BACK_BASE_URL}/pdf/suivireglements/${id}?token=${this.token.id}`, '_blank'); 
		}

		public onRowPrepared(e) {
            if (e.rowType === "data") {
                if ((e.dataIndex % 2) != 0) {
                    e.rowElement.style.cssText = "background-color: #eeeeee;";
				}
                if (e.data.AlertePaid == "1") {
                    e.rowElement.style.cssText = "color: red; background-color: #cce6ff;";
                    // or
                    //e.rowElement.classList.add("my-class");
                    // To override alternation color
                    //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
                }
            }
        }		
	}

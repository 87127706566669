





































































































































































































import {Prop, Watch} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
import {BuyType, Contrat, CVO, PaymentType, Prestation} from '@/models';

import storePrestation from '@/stores/modules/prestation';

import InputDatePicker from '@/components/Input/DatePicker.vue';
import InputPrestationType from '@/components/Input/PrestationType.vue';
import InputEnumSelect from '@/components/Input/EnumSelect.vue';
import InputDepositList from '@/components/Input/DepositList.vue';
import CrudContratSearch from '@/components/Crud/Contrat/Search.vue';
import CrudParcelleSearch from '@/components/Crud/Parcelle/Search.vue';
import CrudChantierSearch from '@/components/Crud/Chantier/Search.vue';

@Component({
	components: {
		InputDatePicker,
		InputPrestationType,
		InputEnumSelect,
		InputDepositList,
		CrudContratSearch,
		CrudParcelleSearch,
		CrudChantierSearch,
	}
})
export default class CrudPrestationEdit extends AbstractEditVue<Prestation> {

	private BuyType = BuyType;
	private CVO = CVO;
	private PaymentType = PaymentType;

	@Prop({ type: Contrat, default: null}) contrat: Contrat;

	public constructor() {
		super(
			storePrestation,
			'Prestation modifié',
			'Prestation créé',
		);
	}

	public async mounted(): Promise<void> {
		await super.mounted();
		
		if (this.item && !this.item.id)
		{
			console.log("create");
			var someDate = new Date();
			someDate.setDate(someDate.getDate() + 365); 
			this.item.dtEnd = someDate;
			this.item.client = this.item.contrat.tiers.name;
		}
	}

	@Watch('contrat')
	public contratWatcher(): void {
		if (this.contrat && this.item) {
			this.item.contrat = this.contrat;
		}
	}

	protected async refresh(): Promise<void> {
		await super.refresh();
		if (this.contrat) {
			this.item.contrat = this.contrat;
		}
	}

	public updateTVA(): void {
		if (this.item.prestationType.sale === false) {
			this.item.tva = 0.0;
		}
		else
		{
			this.item.tva = 0.2;
		}
	}
}

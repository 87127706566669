import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {XHTTPService} from '@/shared/xhttp';
import {DocumentFacture} from '@/models';
import {DocumentFactureService} from '@/xhttp';
import store from '@/stores';
import facture from "@/router/facture";
import storeFacture from "@/stores/modules/facture";

Vue.use(Vuex);

class DocumentFactureState {
}

class DocumentFactureStore {

	@XHTTPService(() => DocumentFacture)
	private documentService: DocumentFactureService;

	public state: DocumentFactureState = new DocumentFactureState();

	public mutations = {
	};

	public actions = {
		async delete(context: ActionContext<DocumentFactureState, any>, document: DocumentFacture): Promise<DocumentFacture> {
			try {
				document.facture.removeDocuments(document);
				return await instance.documentService.delete('/{id}', document);
			} catch (e) {
				storeFacture.dispatch('get', document.facture.id);
				throw e;
			}
		},
	};

}
const instance = new DocumentFactureStore();
const storeDocumentFacture = new Vuex.Store(instance);
store.registerModule('document', storeDocumentFacture);
export default storeDocumentFacture;

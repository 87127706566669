
















































































































































	import {Prop} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import storeFacture from '@/stores/modules/facture';
  	import Checked from '@/components/Checked.vue';

	import {
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxFilterRow,
		DxHeaderFilter,
		DxGroupPanel,
		DxGrouping,
		DxScrolling,
		DxSummary,
		DxLookup,
		DxTotalItem,
		DxGroupItem,
		DxMasterDetail,
		DxStringLengthRule,
		DxRequiredRule,
		DxRangeRule,
		DxValueFormat,
		DxPaging,
		DxStateStoring,
		DxPager,
	} from 'devextreme-vue/data-grid';
	import { createStore } from 'devextreme-aspnet-data-nojquery';
	import DxButton from 'devextreme-vue/button';
	import storeToken from "@/stores/modules/token";
	import {Facture, User, Token} from "@/models";
	import {Stored} from "vue-stored-prop-decorator";
	import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";
	import storeUser from '@/stores/modules/user';
	import CrudFactureEditDialog from '@/components/Crud/Facture/EditDialog.vue';
	import {Emit} from 'vue-property-decorator';

	@Component({
		components: {
      		Checked,
			CrudDeleteDialog,
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxFilterRow,
			DxHeaderFilter,
			DxGroupPanel,
			DxGrouping,
			DxScrolling,
			DxSummary,
			DxLookup,
			DxTotalItem,
			DxGroupItem,
			DxMasterDetail,
			DxStringLengthRule,
			DxRangeRule,
			DxRequiredRule,
			DxValueFormat,
			DxPaging,
			DxPager,
			DxStateStoring,
			DxButton,
			CrudFactureEditDialog,
		}
	})
	export default class CrudFactureList extends Vue {

		@Stored(() => storeToken) token!: Token;
		@Stored(() => storeUser) me!: User; 

		private storeFacture = storeFacture;
		private deletedFacture: Facture = null;
		private dataGrid;
		private editFacture: Facture = null;

		private url = process.env.VUE_APP_BACK_BASE_URL + '/api/factures'

		@Emit()
		private onCreate(): void {
			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
			var oFacture = new Facture();
			oFacture.date = new Date();
			this.editFacture = oFacture;
		}

		public clearFilters() {
			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
			this.dataGrid.instance.clearFilter()
		}

		private dataSource = createStore({
			key: 'id',
			loadUrl: `${this.url}/fetch`,
			onBeforeSend: (method, ajaxOptions) => {
				ajaxOptions.xhrFields = { withCredentials: true };
				ajaxOptions.headers = {
					'Authorization': 'BEARER ' + this.token.id
				}
			},
		});

		private calculateDateStart(data) {
			if (data.date != null) {
				let date = data.date
				let year = date.substr(0,4)
				let month = date.substr(5,2)
				let day = date.substr(8,2)
				return day + '/' + month + '/' + year
			} else {
				return data.date;
			}
		}

		public deleteFacture(id) {
			let chantier = new Facture()
			chantier.id = id;
			this.deletedFacture = chantier
			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
		}

		public onRowPrepared(e) {
            if (e.rowType === "data") {
                if ((e.dataIndex % 2) != 0) {
                    e.rowElement.style.cssText = "background-color: #eeeeee;";
				}
                if (e.data.AlertePaid == "1") {
                    e.rowElement.style.cssText = "color: red; background-color: #cce6ff;";
                    // or
                    //e.rowElement.classList.add("my-class");
                    // To override alternation color
                    //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
                }
            }
        }		
	}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-facture"},[_c('v-card',{staticClass:"general"},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"1","xl":"1"}},[_c('v-icon',{attrs:{"color":"green","size":"50"}},[_vm._v("mdi-account-cash-outline")])],1),_c('v-col',{attrs:{"cols":"12","lg":"11","xl":"11"}},[_c('h3',{staticStyle:{"padding":"10px"}},[_vm._v("Liste des réglements propriétaire en attente")])])],1)],1)],1),(_vm.isGranted('API_POST_FACTURE'))?_c('DxButton',{staticStyle:{"margin":"24px 0"},attrs:{"text":"Supprimer les filtres"},on:{"click":function($event){return _vm.clearFilters()}}}):_vm._e(),_c('DxDataGrid',{ref:"dataGridRefKey",attrs:{"show-borders":true,"data-source":_vm.dataSource,"remote-operations":true,"row-alternation-enabled":false,"allow-column-reordering":true,"allow-column-resizing":false,"column-hiding-enabled":false,"width":'100%'},on:{"row-prepared":_vm.onRowPrepared},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"float-left"},[_vm._v(_vm._s(data.data.name)),_c('br'),_vm._v(_vm._s(data.data.company)+" ")])]}},{key:"contrat",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"float-left"},[_vm._v(_vm._s(data.data.numero)+" "),_c('v-btn',{attrs:{"icon":"","color":"see","title":"Afficher contrat","to":{ name: 'contrat-show', params: { id: data.data.contrat_id } }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}},{key:"suivi-payer",fn:function(ref){
var data = ref.data;
return [(data.data.payer !== '0')?_c('div',{staticStyle:{"padding-top":"5px"}},[_c('v-icon',{attrs:{"color":"green","title":"Billons livrés"}},[_vm._v("mdi-cash-multiple")])],1):_c('div',[(data.data.alerte == '3')?_c('v-icon',{attrs:{"color":"red","title":"non payer"}},[_vm._v("mdi-cash-multiple")]):_vm._e(),(data.data.alerte == '2')?_c('v-icon',{attrs:{"color":"orange","title":"non payer"}},[_vm._v("mdi-cash-multiple")]):_vm._e(),(data.data.alerte == '1')?_c('v-icon',{attrs:{"color":"yellow","title":"non payer"}},[_vm._v("mdi-cash-multiple")]):_vm._e()],1)]}},{key:"action-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","color":"green","title":"Afficher prestation","to":{ name: 'prestation-show', params: { id: data.data.prestation_id } }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}])},[_c('DxColumn',{attrs:{"fixed":true,"width":105,"data-field":"dt_depot","caption":"Date","data-type":"date","calculate-cell-value":_vm.calculateDateStart}}),_c('DxColumn',{attrs:{"width":150,"allow-grouping":false,"data-field":"numero","caption":"Contrat","cell-template":"contrat"}}),_c('DxColumn',{attrs:{"width":250,"data-field":"name","caption":"Client","cell-template":"name"}}),_c('DxColumn',{attrs:{"width":220,"allow-filtering":false,"data-field":"prestation_type","caption":"Type"}}),_c('DxColumn',{attrs:{"width":150,"allow-filtering":false,"data-field":"value","data-type":"number","caption":"Montant HT","format":"#,### €"}}),_c('DxColumn',{attrs:{"allow-grouping":false,"allow-sorting":false,"allow-filtering":false,"width":120,"caption":"Action","cell-template":"action-template"}}),_c('DxStateStoring',{attrs:{"enabled":false,"type":"sessionStorage","storage-key":"storage-list-reglement-attente"}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxPaging',{attrs:{"page-size":50}}),_c('DxPager',{attrs:{"show-page-size-selector":true,"allowed-page-sizes":[50, 100, 200]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
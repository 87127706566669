






























































































































































































































































































































































































































































































































































































































































































































































































































	import {Emit} from "vue-property-decorator";
	import {Stored} from "vue-stored-prop-decorator";
	import {Component} from '@/shared/component';
	import {EnumLiteral} from "@/shared/utils";
    import {
BoisSize,
        BuyType,
        CubagePrestationEstimed,
        CVO,
        Parcelle,
        PaymentType,
		Prestation, 
		Token
    } from "@/models";
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';

	import storePrestation from '@/stores/modules/prestation';
    import storeParcelle from "@/stores/modules/parcelle";
    import storeCubagePrestationEstimed from "@/stores/modules/cubagePrestationEstimed";
	import storeToken from "@/stores/modules/token";

	import CrudParcelleEditDialog from '@/components/Crud/Parcelle/EditDialog.vue';
	import CrudPrestationEditDialog from '@/components/Crud/Prestation/EditDialog.vue';
	import CrudPrestationEditDialogAlerte from '@/components/Crud/Prestation/EditDialogAlerte.vue';
    import CrudPrestationEditDialogPaiement from '@/components/Crud/Prestation/EditDialogPaiement.vue';
    import CrudPrestationEditDialogPriceM3 from '@/components/Crud/Prestation/EditDialogPriceM3.vue';
    import CrudPrestationEditDialogParcelles from '@/components/Crud/Prestation/EditDialogParcelles.vue';
    import CrudPrestationEditDialogReglements from '@/components/Crud/Prestation/EditDialogReglements.vue';
    import CrudCubagePrestationEstimedFilter from '@/components/Crud/CubagePrestationEstimed/Filter.vue';
    import CrudCubagePrestationEstimedList from '@/components/Crud/CubagePrestationEstimed/List.vue';
    import CrudCubagePrestationEstimedEditDialog from '@/components/Crud/CubagePrestationEstimed/EditDialog.vue';
	import CrudParcelleShowReduce from '@/components/Crud/Parcelle/ShowReduce.vue';
	import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
	import CrudTiersShow from '@/components/Crud/Tiers/Show.vue';
	import CrudTiersEdit from '@/components/Crud/Tiers/Edit.vue';
	import Checked from '@/components/Checked.vue';
	import CrudDocumentPrestationUploader from '@/components/Crud/DocumentPrestation/Uploader.vue';
    import CrudCubagePrestationEstimedEditDocumentDialog from '@/components/Crud/CubagePrestationEstimed/EditDialogDocument.vue';
	import CrudDocumentPrestationShow from '@/components/Crud/DocumentPrestation/Show.vue';
	import CrudDocumentCubagePourClientUploader from '@/components/Crud/DocumentCubagePourClient/Uploader.vue';
	import CrudDocumentCubagePourClientShow from '@/components/Crud/DocumentCubagePourClient/Show.vue';
	import DialogConfirm from '@/components/Dialog/Confirm.vue';

	import CrudDocumentPrestationCubageUploader from '@/components/Crud/DocumentPrestationCubage/Uploader.vue';
	import CrudDocumentPrestationCubageShow from '@/components/Crud/DocumentPrestationCubage/Show.vue';
	import axios from "axios";

	@Component({
		components: {
			CrudDocumentPrestationCubageUploader,
			CrudDocumentPrestationCubageShow,
            CrudParcelleEditDialog,
			CrudPrestationEditDialogAlerte,
			CrudPrestationEditDialog,
			CrudPrestationEditDialogPaiement,
			CrudPrestationEditDialogPriceM3,
			CrudPrestationEditDialogParcelles,
			CrudPrestationEditDialogReglements,
            CrudCubagePrestationEstimedFilter,
            CrudCubagePrestationEstimedEditDialog,
            CrudCubagePrestationEstimedList,
			CrudParcelleShowReduce,
			CrudDeleteDialog,
			CrudTiersShow, 
			CrudTiersEdit,
			Checked,
			CrudDocumentPrestationUploader,
			CrudDocumentPrestationShow,
			CrudDocumentCubagePourClientUploader,
			CrudDocumentCubagePourClientShow,
			CrudCubagePrestationEstimedEditDocumentDialog,
			DialogConfirm,
		}
	})
	export default class CrudPrestationShow extends AbstractShowVue<Prestation> {

		@Stored(() => storeToken)      private token: Token;

		private editPrestation: Prestation = null;
		private editPrestationAlerte: Prestation = null;
		private editPrestationM3: Prestation = null;
		private editParcelles: Prestation = null;
		private editReglements: Prestation = null;
		private editPrestationPaiement: Prestation = null;

        private editCubagePrestationEstimed: CubagePrestationEstimed = null;
        private deletedCubagePrestationEstimed: CubagePrestationEstimed = null;
		//private editParcelle: Parcelle = null;
        //private deletedParcelle: Parcelle = null;
        private editCubagePrestationEstimedDocument: CubagePrestationEstimed = null;

		private storePrestation = storePrestation;
		private storeCubagePrestationEstimed = storeCubagePrestationEstimed;
		private storeParcelle = storeParcelle;
		private expendParcelle: number = 0;
		
		private CVO = CVO;
		private BuyType = BuyType;
		private PaymentType = PaymentType;

		private markPaidConfirm: boolean = false;
		private markUnPaidConfirm: boolean = false;
		private markPayPreparedConfirm: boolean = false;
		private markUnPayPreparedConfirm: boolean = false;

		public constructor() {
			super(storePrestation);
		}

		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/prestation_attestation/${this.item.id}?token=${this.token.id}`;
		}

        public created(): void {
        }

        public endEditParcelles(): void {
        }

		private enumLabel(prefix: string, enumType: any, value: any): string {
			return 'enum.'+prefix+'.'+EnumLiteral.key(enumType, value).toLowerCase();
		}

		@Emit()
		private onCreateCubagePrestationEstimed(): void {
			this.editCubagePrestationEstimed = new CubagePrestationEstimed();
			this.editCubagePrestationEstimed.prestation = this.item;
			this.editCubagePrestationEstimed.coef = 0.9;
			this.editCubagePrestationEstimed.coefStere = 0.63;
			// On met entier par defaut
			this.editCubagePrestationEstimed.boisSize = new BoisSize();
			this.editCubagePrestationEstimed.boisSize.id = 7;
		}

		private refreshCubagePrestationEstimed(): void {
			storeCubagePrestationEstimed.dispatch('getC', { filters: true });
		}
		
		private async prestationMarkPaid(): Promise<void> {
			await storePrestation.dispatch('putMarkPaid', this.item);
			this.refresh(this.item, true);
		}
		
		private async prestationMarkNotPayPrepared(): Promise<void> {
			await storePrestation.dispatch('putMarkNotPayPrepared', this.item);
			this.refresh(this.item, true);
		}

		private async prestationMarkPayPrepared(): Promise<void> {
			await storePrestation.dispatch('putMarkPayPrepared', this.item);
			this.refresh(this.item, true);
		}

		private async depositMarkPay(id :number): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/prestations/putdepositpaid/" + id + "?token=" + this.token.id, {});
			this.refresh(this.item, true);
		}

		private async depositMarkUnPay(id :number): Promise<void> {
			await axios.put(process.env.VUE_APP_BACK_BASE_URL + "/api/prestations/putdepositunpaid/" + id + "?token=" + this.token.id, {});
			this.refresh(this.item, true);
		}
}

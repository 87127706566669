























































































	import {Prop, Watch} from "vue-property-decorator";
	import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {BoisQuality, BoisType, CubageFacture} from '@/models';
	import {ObjectHelper} from "@/shared/utils";

	import storeCubageFacture from '@/stores/modules/cubageFacture';

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputBoisQuality from '@/components/Input/BoisQuality.vue';

	@Component({
		components: {
			InputBoisType,
			InputBoisQuality,
		}
	})
	export default class CrudCubageFactureEdit extends AbstractEditVue<CubageFacture> {
		
		private savedisabled: Boolean = true;
		private showMessage: Boolean = false;

		public constructor() {
			super(
				storeCubageFacture,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		protected async refresh(): Promise<void> {
			await super.refresh();

			this.watcher();
		}

		public onChange()
		{
		}

		public watcher(): void {
		}

}

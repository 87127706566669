








































import {Stored} from 'vue-stored-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {Emit} from 'vue-property-decorator';
import {Facture} from '@/models';
import CrudFactureShowDialog from '@/components/Crud/Facture/ShowDialog.vue';
import CrudFactureList from '@/components/Crud/Facture/List.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import storeFacture from '@/stores/modules/facture';
import axios from "axios";
import storeToken from "@/stores/modules/token";
import {Token, User} from "@/models";

@Component({
	components: {
		CrudFactureShowDialog,
		CrudFactureList,
		CrudDeleteDialog,
	}
})
export default class FacturesView extends Vue {
	
	@Stored(() => storeToken) private token!: Token;
	@Stored(() => storeFacture)

	private showFacture: Facture = null;
	private editFacture: Facture = null;
	private deletedFacture: Facture = null;
	private storeFacture = storeFacture;
	private isNew: boolean = false;
	private data = null;

	public async mounted(): Promise<void> {
		await this.LoadEncours();
	}

	private async LoadEncours(): Promise<void> 
	{
		await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/factures/fetch_encours?token=" + this.token.id, {}).then(async response => {
			this.data = await response.data;
		});

	}


	@Emit()
	private onCreate(): void {
		this.isNew = true;
		var oFacture = new Facture();
		oFacture.date = new Date();
		this.editFacture = oFacture;
	}
	
	private refresh(): void {
		this.isNew = false;
		//storeFacture.dispatch('getC', { filters: true });
	}
}

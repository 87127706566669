import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {AscDesc, ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisQuality, BoisType, Facture, CubageFacture} from '@/models';
import {CubageFactureService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex);

export class CubageFactureFilter {

	public boisType:     BoisType[] = [];
	public boisQuality:  BoisQuality[] = [];
	public facture:     Facture[] = [];

	public clear(): void {
		this.boisType = [];
    	this.facture = [];
		this.boisQuality = [];
	}

	public toString(): string {
		return JSON.stringify({
			boisType    : this.boisType.map(b => b.id),
			boisQuality : this.boisQuality.map(b => b.id),
			facture    : this.facture.map(f => f.id),
		});
	}
}

class CubageFactureState extends PaginatorFilterState<CubageFactureFilter> {
	public cubageFacture: CubageFacture = null;
	public cubageFactures: ResultType<CubageFacture> = null;
	public constructor() {
		super(new CubageFactureFilter(), 'cubageFactures', 'cubageFacture', 'id', AscDesc.DESC, 20);
	}
}

class CubageFactureStore {

	@XHTTPService(() => CubageFacture)
	private cubageFactureService: CubageFactureService;
	private  _store: Store<any>;

	public state: CubageFactureState = new CubageFactureState();

	public mutations = {
		...PaginatorMutationFilter(() => this._store, 'cubageFactures', 'cubageFacture'),
	};

	public actions = { 
		...PaginatorActionWithFilters<CubageFacture, CubageFactureState>(() => CubageFacture, 'cubageFactures', 'cubageFacture'),
		...StandardAction<CubageFacture, CubageFactureState>(() => CubageFacture, 'cubageFacture'),
	};

	public init(store: Store<any>): this {
		this._store = store;
		return this;
	}
}

const instance = new CubageFactureStore();
const storeCubageFacture = new Vuex.Store(instance);
store.registerModule('cubageFacture', storeCubageFacture);
export default storeCubageFacture;

